// Font Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


// Theme
@import './scss/theme';

// Libs
@import './scss/libs/primeflex/primeflex.scss';

// Components
@import './scss/components';

// Common
@import './scss/common';

// Base
@import './scss/base';

// Snackbar Severity
/* design filled */
@import './libs/snackbar/mat-snackbar-severity-filled';

/* design light outlined */
// @import './snackbar/mat-snackbar-severity-outlined.scss';