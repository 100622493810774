@use 'sass:map';
@import '../abstracts';
@import '../libs/primeflex/core/variables';

:root {
  $light-config: mat-get-color-config($light-theme);
  $dark-config: mat-get-color-config($dark-theme);

  --light-surface-a: #{map.get($light-config, background, card)};
  --dark-surface-a: #{map.get($dark-config, background, card)};
  --light-surface-b: #{map.get($light-config, background, background)};
  --dark-surface-b: #{map.get($dark-config, background, background)};
  --light-surface-c: #{map.get($light-config, background, app-bar)};
  --dark-surface-c: #{map.get($dark-config, background, app-bar)};

  --breakpoint-sm: #{map.get($breakpoints, sm)};
  --breakpoint-md: #{map.get($breakpoints, md)};
  --breakpoint-lg: #{map.get($breakpoints, lg)};
  --breakpoint-xl: #{map.get($breakpoints, xl)};

  --dashboard-sidenav-width: 4rem;
  --dashboard-sidenav-width-expanded: 16rem;
  --dashboard-sidenav-width-expanded-mobile: 40%;
  --dashboard-toolbar-height: 64px;
}
