@use 'sass:map';
@use '@angular/material' as mat;
@import './abstracts';

@include mat.core();
@include mat.all-component-themes($light-theme);

.light-mode {
  @include theme-color-grabber($light-theme);
}

@include dark-mode() {
  $severity-is-dark-theme: true;
  @import './libs/snackbar/mat-snackbar-severity-filled';
  @include mat.all-component-colors($dark-theme);
  @include theme-color-grabber($dark-theme);
}
