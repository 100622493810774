@import '@angular/material/theming';
@import '../abstracts';

.minimalist-scrollbar {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.122);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.19);
  }
}

@include dark-mode() {
  .minimalist-scrollbar {
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--light-surface-a);
    }
  }
}
