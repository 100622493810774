@import './abstracts';

.pointer {
  cursor: pointer;
}

.spinner {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bg-success {
  background-color: var(--success-color) !important;
}
.bg-warning {
  background-color: var(--warning-color) !important;
}
.bg-error {
  background-color: var(--error-color) !important;
}
.bg-info {
  background-color: var(--info-color) !important;
}
.border-0 {
  border-color: var(--border-0) !important;
}
.border-shadow {
  border-color: var(--border-shadow) !important;
}

.border-danger,
.text-danger {
  color: var(--error-color) !important;
}
