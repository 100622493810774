@import '../abstracts';
html {
  font-size: 12px;

  @include respond-to(md) {
    font-size: 14px;
  }
  @include respond-to(lg) {
    font-size: 16px;
  }
}
