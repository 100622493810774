@import './abstracts';

.mat-mdc-paginator-page-size,
.mat-mdc-paginator-range-label {
  display: none !important;
}

.mat-mdc-paginator-page-size,
.mat-mdc-paginator-range-label {
  @include respond-to(sm) {
    display: flex !important;
  }
}
