@import './mat-snackbar-severity-common';

@include severity-container {
  --mdc-snackbar-supporting-text-color: white !important;
  --mat-snack-bar-button-color: white !important;

  @include severity-error {
    background-color: #{$error-color} !important;
    --mdc-snackbar-container-color: #{$error-color} !important;
  }

  @include severity-warning {
    background-color: #{$warning-color} !important;
    --mdc-snackbar-container-color: #{$warning-color} !important;
  }

  @include severity-info {
    background-color: #{$info-color} !important;
    --mdc-snackbar-container-color: #{$info-color} !important;
  }

  @include severity-success {
    background-color: #{$success-color} !important;
    --mdc-snackbar-container-color: #{$success-color} !important;
  }

}
