// Define a custom mixin that takes in the current theme
@use 'sass:map';
@import '@angular/material/theming';

@mixin theme-color-grabber($theme) {
  $config: mat-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  // Create theme specfic styles
  --primary-color: #{mat-get-color-from-palette($primary, default)};
  --accent-color: #{mat-get-color-from-palette($accent, default)};
  --warn-color: #{mat-get-color-from-palette($warn, default)};
  --primary-default-contrast: #{map.get($primary, default-contrast)};
  --accent-default-contrast: #{map.get($accent, default-contrast)};
  --warn-default-contrast: #{map.get($warn, default-contrast)};
  --primary-lighter-contrast: #{map.get($primary, lighter-contrast)};
  --accent-lighter-contrast: #{map.get($accent, lighter-contrast)};
  --warn-lighter-contrast: #{map.get($warn, lighter-contrast)};
  --primary-darker-contrast: #{map.get($primary, darker-contrast)};
  --accent-darker-contrast: #{map.get($accent, darker-contrast)};
  --warn-darker-contrast: #{map.get($warn, darker-contrast)};
  --surface-a: #{map.get($background, card)};
  --surface-b: #{map.get($background, background)};
  --surface-c: #{map.get($background, app-bar)};
  --foreground-text-color: #{map.get($foreground, text)};
  --foreground-secondary-text-color: #{map.get($foreground, secondary-text)};

  --primary-palette-50: #{mat-color($primary, 50)};
  --primary-palette-100: #{mat-color($primary, 100)};
  --primary-palette-200: #{mat-color($primary, 200)};
  --primary-palette-300: #{mat-color($primary, 300)};
  --primary-palette-400: #{mat-color($primary, 400)};
  --primary-palette-500: #{mat-color($primary, 500)};
  --primary-palette-600: #{mat-color($primary, 600)};
  --primary-palette-700: #{mat-color($primary, 700)};
  --primary-palette-800: #{mat-color($primary, 800)};
  --primary-palette-900: #{mat-color($primary, 900)};
  --primary-palette-A50: #{mat-color($primary, A50)};
  --primary-palette-A100: #{mat-color($primary, A100)};
  --primary-palette-A200: #{mat-color($primary, A200)};
  --primary-palette-A300: #{mat-color($primary, A300)};
  --primary-palette-A400: #{mat-color($primary, A400)};
  --primary-palette-A500: #{mat-color($primary, A500)};
  --primary-palette-A600: #{mat-color($primary, A600)};
  --primary-palette-A700: #{mat-color($primary, A700)};
  --primary-palette-A800: #{mat-color($primary, A800)};
  --primary-palette-A900: #{mat-color($primary, A900)};
  --accent-palette-50: #{mat-color($accent, 50)};
  --accent-palette-100: #{mat-color($accent, 100)};
  --accent-palette-200: #{mat-color($accent, 200)};
  --accent-palette-300: #{mat-color($accent, 300)};
  --accent-palette-400: #{mat-color($accent, 400)};
  --accent-palette-500: #{mat-color($accent, 500)};
  --accent-palette-600: #{mat-color($accent, 600)};
  --accent-palette-700: #{mat-color($accent, 700)};
  --accent-palette-800: #{mat-color($accent, 800)};
  --accent-palette-900: #{mat-color($accent, 900)};
  --accent-palette-A50: #{mat-color($accent, A50)};
  --accent-palette-A100: #{mat-color($accent, A100)};
  --accent-palette-A200: #{mat-color($accent, A200)};
  --accent-palette-A300: #{mat-color($accent, A300)};
  --accent-palette-A400: #{mat-color($accent, A400)};
  --accent-palette-A500: #{mat-color($accent, A500)};
  --accent-palette-A600: #{mat-color($accent, A600)};
  --accent-palette-A700: #{mat-color($accent, A700)};
  --accent-palette-A800: #{mat-color($accent, A800)};
  --accent-palette-A900: #{mat-color($accent, A900)};
  --warn-palette-50: #{mat-color($warn, 50)};
  --warn-palette-100: #{mat-color($warn, 100)};
  --warn-palette-200: #{mat-color($warn, 200)};
  --warn-palette-300: #{mat-color($warn, 300)};
  --warn-palette-400: #{mat-color($warn, 400)};
  --warn-palette-500: #{mat-color($warn, 500)};
  --warn-palette-600: #{mat-color($warn, 600)};
  --warn-palette-700: #{mat-color($warn, 700)};
  --warn-palette-800: #{mat-color($warn, 800)};
  --warn-palette-900: #{mat-color($warn, 900)};
  --warn-palette-A50: #{mat-color($warn, A50)};
  --warn-palette-A100: #{mat-color($warn, A100)};
  --warn-palette-A200: #{mat-color($warn, A200)};
  --warn-palette-A300: #{mat-color($warn, A300)};
  --warn-palette-A400: #{mat-color($warn, A400)};
  --warn-palette-A500: #{mat-color($warn, A500)};
  --warn-palette-A600: #{mat-color($warn, A600)};
  --warn-palette-A700: #{mat-color($warn, A700)};
  --warn-palette-A800: #{mat-color($warn, A800)};
  --warn-palette-A900: #{mat-color($warn, A900)};
}
